import styled from '@emotion/styled'
import { MenuItem } from '@mui/material'
import { Field } from "formik"
import { TextField } from "formik-mui"

const Styled = {
  Field: styled(Field)`
    width: 100%;
  `,
}

type ContactUsFieldProps<T> = {
  name: string
  label: string
  required?: boolean
  values: T[]
  map(value: T): { id: string; name: string }
}

function CommonSelect<T>({
  name,
  label,
  required = false,
  values,
  map,
}: ContactUsFieldProps<T>) {
  return (
    <Styled.Field
      component={TextField}
      select
      name={name}
      required={required}
      label={label}
      helperText=" "
    >
      {values.map((value, i) => {
        const { name, id } = map(value)
        return (
          <MenuItem key={i} value={id}>
            {name}
          </MenuItem>
        )
      })}
    </Styled.Field>
  )
}

export default CommonSelect
