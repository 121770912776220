import styled from "@emotion/styled"
import { Button, Divider, SvgIcon, Typography } from "@mui/material"
import { Desktop, Mobile } from "common/MediaQueries"

const SwiperButton = styled.a<{ visible: boolean }>`
  font-family: swiper-icons;
  text-transform: none !important;
  letter-spacing: 0;
  font-size: 55px;
  cursor: pointer;
  color: ${(p) => p.theme.colors.marine};
  /* margin: 0 60px; */
  user-select: none;
  visibility: ${(p) => (p.visible ? "visible" : "hidden")};
  &.swiper-button-disabled {
    opacity: 0.3;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
`

const Shared = {
  Mobile,
  Desktop,
  Header: styled(Typography)`
    font-size: 24px;
    font-weight: bold;
    color: ${(p) => p.theme.colors.marine};
    text-align: center;
    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 34px;
    }
  `,
  Divider: styled(Divider, {
    shouldForwardProp: (prop) => prop !== "flexItem",
  })`
    height: 2px;
    background-color: ${(p) => p.theme.colors.silver};

    ${(p) => p.theme.breakpoints.down("sm")} {
      margin-left: 15px;
      margin-right: 15px;
    }
  `,
  SimpleDivider: styled(Divider)`
    height: 2px;
    margin-top: 7px;
    margin-bottom: 7px;
    background-color: ${(p) => p.theme.colors.silver};
  `,
  Reverse: styled.div`
    display: flex;
    flex-direction: column;
    ${(p) => p.theme.breakpoints.up("md")} {
      flex-direction: column-reverse;
    }
  `,
  Button: styled(Button)`
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-top: 15px;
    }
  `,
  ButtonPrev: styled(SwiperButton)`
    margin-left: 74px;
    margin-right: 58px;
    :after {
      content: "prev";
    }
    &.swiper-button-lock {
      display: block !important;
      visibility: hidden;
    }
  `,
  ButtonNext: styled(SwiperButton)`
    margin-left: 58px;
    margin-right: 74px;
    :after {
      content: "next";
    }
    &.swiper-button-lock {
      display: block !important;
      visibility: hidden;
    }
  `,
  OptionsSVGIcon: styled(SvgIcon)`
    fill: ${(p) => p.theme.colors.bluishGrey};
  `,
}

export default Shared
