/** THIS FILE IS AUTOMATICALLY GENERATED FROM : https://preprod-api-cv.ford.co.il/swagger/v1/swagger.json **/

import axios from "../axios"
import * as Ford from "./FordInterfaces"

export const getBanner = () =>
  axios.get<Ford.BannerDtoListResponseWrapper>(`/api/banner`, { headers: {} })

export const getBannerKey = (key: string) =>
  axios.get<Ford.BannerDtoResponseWrapper>(`/api/banner/${key}`, {
    headers: {},
  })

export const postCacheClearCache = (data: Ford.CacheKey) =>
  axios.post<Ford.BooleanResponseWrapper>(`/api/cache/clearCache`, data, {
    headers: {},
  })

export const postCacheClearAllCache = () =>
  axios.post<Ford.BooleanResponseWrapper>(`/api/cache/clearAllCache`, {
    headers: {},
  })

export const postContact = (data: Ford.Contact) =>
  axios.post<Ford.BooleanResponseWrapper>(`/api/contact`, data, { headers: {} })

export const getContactSalesContacts = () =>
  axios.get<Ford.SalesContactDtoListResponseWrapper>(
    `/api/contact/salesContacts`,
    { headers: {} },
  )

export const getHomepage = () =>
  axios.get<Ford.HomePageResponseResponseWrapper>(`/api/homepage`, {
    headers: {},
  })

export const getBenefitView = (
  serviceCenterName: string,
  benefitId: number /* int32 */,
  licenceNumber: string,
) =>
  axios.get<any>(`/BenefitView`, {
    params: { serviceCenterName, benefitId, licenceNumber },
    headers: {},
  })

export const getBenefitPdf = (
  serviceCenterName: string,
  benefitId: number /* int32 */,
  licenceNumber: string,
) =>
  axios.get<any>(`/BenefitPdf`, {
    params: { serviceCenterName, benefitId, licenceNumber },
    headers: {},
  })

export const getSeoKey = (key: string) =>
  axios.get<Ford.SeoMetaDataDtoResponseWrapper>(`/api/seo/${key}`, {
    headers: {},
  })

export const getServiceCenter = () =>
  axios.get<Ford.ServiceCenterDtoListResponseWrapper>(`/api/serviceCenter`, {
    headers: {},
  })

export const getServiceCenterId = (id: string) =>
  axios.get<Ford.SingleServiceCenterDtoResponseWrapper>(
    `/api/serviceCenter/${id}`,
    { headers: {} },
  )

export const postServiceCenterContact = (data: Ford.Appointment) =>
  axios.post<Ford.BooleanResponseWrapper>(`/api/serviceCenter/contact`, data, {
    headers: {},
  })

export const getGeneralSettings = () =>
  axios.get<Ford.GeneralSettingDtoListResponseWrapper>(`/api/generalSettings`, {
    headers: {},
  })

export const getVehicleModels = () =>
  axios.get<Ford.ModelsVehicleDtoListResponseWrapper>(`/api/vehicle/models`, {
    headers: {},
  })

export const getVehicleId = (id: string) =>
  axios.get<Ford.VehicleDtoResponseWrapper>(`/api/vehicle/${id}`, {
    headers: {},
  })

export const getVehicle = () =>
  axios.get<Ford.VehicleDtoListResponseWrapper>(`/api/vehicle`, { headers: {} })
