import styled from '@emotion/styled'
import { useFormikContext } from "formik"
import Shared from "theme/shared"

type Align = "center" | "right"

const Styled = {
  Button: styled(Shared.Button)<{ align: Align }>`
    display: block;
    width: 100%;

    margin: ${(p) => (p.align == "center" ? "0 auto 40px" : "0 0 40px")};
    ${(p) => p.theme.breakpoints.up("md")} {
      width: 340px;
    }
  `,
}

type CommonSubmitProps = {
  label: string
  align?: Align
}

const CommonSubmit = ({ label, align = "right" }: CommonSubmitProps) => {
  const { submitForm } = useFormikContext()
  return (
    <Styled.Button variant="contained" onClick={submitForm} align={align}>
      {label}
    </Styled.Button>
  )
}

export default CommonSubmit
